<template>
<b-card :title="$t('products')">
  <b-row>
    <b-col md="12" class="my-1">
      <div class="text-right">
        <router-link v-if="user.role === 'admin'" :to="{ name: 'products.create' }" class="btn btn-outline-success btn-sm"><fa icon="plus" /></router-link>
      </div>
    </b-col>
  </b-row>
  <b-tabs content-class="mt-3" v-on:activate-tab="tabActivated">
    <groups />
    <characteristics />
    <stages />
    <prices />
  </b-tabs>
</b-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Characteristics from './filter/characteristics'
import Stages from './filter/stages'
import Groups from './filter/groups'
import Prices from './filter/prices'
export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
      return { title: this.$t('products') }
    },
    components: {
      Characteristics,
      Stages,
      Groups,
      Prices
    },
    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },
    methods: {
      ...mapActions({
        setTab: 'productTab/setTab'
      }),
      tabActivated (newTabIndex,oldTabIndex, event) {
        const tab = event.vueTarget.tabs[newTabIndex]
        if (typeof tab !== 'undefined') {
          this.setTab(tab.id)
        }
      }
    }
}
</script>
