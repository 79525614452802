<template>
<b-tab :title="$t('products')">
    <b-row class="mb-2">
        <b-col md="10" class="my-1">
          <b-form-group :label="$t('search')" label-cols-sm="1" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
            <select class="form-control" @change="searchBySelect('userId', $event)">
              <option value="">--</option>
              <option v-for="user in users" :key="user.id" :value="user.id">{{ user.first_name }} {{ user.last_name }}</option>
            </select>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <div class="text-right">
            <b-button-group>
              <b-button size="sm" v-b-toggle.collapse-2>{{ $t('change_price') }}</b-button>
              <b-button size="sm" variant="danger" @click="removeItems($event)" :title="$t('delete_all')">
                <fa icon="trash" />
              </b-button>
            </b-button-group>
          </div>
        </b-col>
        <b-col sm="9">
          <b-collapse id="collapse-2">
            <b-form-group :label="$t('user')" label-cols-sm="2" label-align-sm="right" label-size="sm" label-for="filterInput">
                <select class="form-control" multiple placeholder="Select user" v-model="userSelected">
                  <option v-for="user in users" :key="user.id" :value="user.id">{{ user.first_name }} {{ user.last_name }}</option>
                </select>
            </b-form-group>
            <b-form-group :label="$t('price')" label-cols-sm="2" label-align-sm="right" label-size="sm" label-for="filterInput">
                <b-form-input v-model="price" type="number" :placeholder="$t('price')"></b-form-input>
            </b-form-group>
            <b-form-group label-cols-sm="2" label-align-sm="right" label-size="sm" label-for="filterInput">
                <b-button size="sm" variant="primary" @click="setPriceToUsers">
                  {{ $t('change') }}
                </b-button>
            </b-form-group>
          </b-collapse>
        </b-col>
      </b-row>
      <b-table v-if="items" striped show-empty sort-icon-left small :items="items" :fields="fields" :current-page="currentPage" :per-page="0">
        <template slot="top-row" slot-scope="{ fields }">
          <td v-for="field in fields" :key="field.key">
            <b-form-input v-if="field.typesort === 'text'" size="sm" :placeholder="field.label" @keyup.enter="searchByText(field.key, $event)"></b-form-input>
            <div v-if="field.key === 'group'" class="form-group" >
              <select class="form-control form-control-sm" @change="searchByGroup(field.key, $event)">
                <option value=""> -- </option>
                <optgroup v-for="group in groups" :key="group.id" :label="group.name">
                  <option v-for="child in group.children" :key="child.id" :value="child.id">{{ child.name }}</option>
                </optgroup>
              </select>
            </div>
            <b-input-group v-if="field.key === 'product'">
              <list-select
              v-if="productOptions"
                :list="productOptions"
                option-value="id"
                option-text="name"
                :placeholder="$t('nothing_selected')"
                :selected-item="productSelected"
                @select="onSelectProduct"
                class="form-control form-control-sm"></list-select>
            </b-input-group>
            <div v-if="field.key === 'characteristic'" class="form-group">
              <list-select
              v-if="characteristicsOptions"
                :list="characteristicsOptions"
                option-value="id"
                option-text="name"
                :placeholder="$t('nothing_selected')"
                :selected-item="characteristicSelected"
                @select="onSelectCharacteristic"
                class="form-control form-control-sm"></list-select>
            </div>
          </td>
        </template>
        <template v-slot:head(selected)>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="customCheck1" @click="selectAll" v-model="allSelected">
            <label class="custom-control-label" for="customCheck1"></label>
          </div>
        </template>
        <template v-slot:cell(selected)="row">
          <div class="custom-control custom-checkbox">
            <input
            type="checkbox"
            class="custom-control-input"
            :id="`checkbox-${row.item.id}`"
            :name="`checkbox-${row.item.id}`"
            :value="row.item.id"
            :checked="row.item.selected"
            @change="selectItem(row.item.id, row.item.type, $event)">
            <label class="custom-control-label" :for="`checkbox-${row.item.id}`"></label>
          </div>
        </template>
        <template v-slot:cell(image)="row">
          <b-img-lazy :src="row.item.image" thumbnail width="100"/>
        </template>
        <template v-slot:cell(price)="row">
          <span class="badge badge-danger">{{ row.item.price | currency }}</span>
        </template>
        <template v-slot:cell(price_drop)="row">
          <span class="badge badge-success">{{ row.item.price_drop | currency }}</span>
        </template>
        <template v-slot:cell(actions)="row">
          <b-button-group>
            <b-button size="sm" variant="info" @click="info(row.item, row.index, $event.target)">
              <fa icon="eye" />
            </b-button>
            <b-button size="sm" variant="primary" @click="copyModal(row.item, row.index, $event.target)"><fa icon="copy" /></b-button>
            <router-link v-if="user.role === 'admin'" :to="{ name: 'products.edit', params: { 'id': row.item.id } }" class="btn btn-sm btn-warning"><fa icon="pencil-alt" /></router-link>
            <b-button size="sm" variant="danger" @click="removeItem(row.item.id)">
              <fa icon="trash" />
            </b-button>
          </b-button-group>
        </template>
      </b-table>
    <!-- Pagination -->
    <b-row>
      <b-col>
        <b-pagination size="sm" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"/>
      </b-col>
      <b-col>
        <b-form-group
          label="К-сть на стр"
          label-for="per-chars-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-chars-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Modal info -->
    <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal" size="xl">
        <div v-html="infoModal.content"></div>
    </b-modal>
    <b-modal id="copyModal" title="Копіювання товару" ok-only hide-footer>
      <div v-if="copyProduct">
        <h6>{{ copyProduct.title }}</h6>
        <div class="form-group">
          <label for="">Назви товарів, через ,(кому)</label>
          <textarea class="form-control" v-model="copyTextarea"></textarea>
          <small>Задайте товари в форматі <strong>товар:код,товар:код</strong> і вони будуть скопійовані</small>
        </div>
        <div class="text-right">
          <button class="btn btn-sm btn-success" type="button" @click="copyProductItem(copyProduct.id)">{{ $t('copy') }}</button>
        </div>
      </div>
    </b-modal>
</b-tab>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex'
import { ListSelect } from 'vue-search-select'
export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('products') }
    },
    components: {
      ListSelect
    },
    data: () => ({
      users: [],
      items: [],
      fields: [
        {
          key: 'selected',
          label: '',
          sortable: false
        },
        {
          key: 'sku',
          label: 'Код товару',
          typesort: 'text'
        },
        {
          key: 'group',
          label: 'Група',
          typesort: 'select'
        },
        {
          key: 'product',
          label: 'Назва',
          typesort: 'select'
        },
        {
          key: 'characteristic',
          label: 'Характеристика',
          class: 'text-center',
          typesort: 'select'
        },
        {
          key: 'images',
          label: 'Фото'
        },
        {
          key: 'price',
          label: 'Ціна 1c',
          class: 'text-center',
          typesort: 'text'
        },
        {
          key: 'price_drop',
          label: 'Ціна дроп.',
          class: 'text-center',
          typesort: 'text'
        },
        {
          key: 'total_quantity',
          label: 'В наяв',
          class: 'text-center',
          typesort: 'text'
        },
        {
          key: 'production_quantity',
          label: 'Мож.виг',
          class: 'text-center',
          typesort: 'text'
        },
        {
          key: 'actions',
          label: 'Дії',
          class: 'text-center'
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      pageOptions: [5, 10, 15, 50, 100, 250, 500, 1000, 1500, 2000, 5000],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      selected: [],
      allSelected: false,
      selectIds: [],
      selectedCharacteristicsIds: [],
      search: '',
      search_sku: '',
      groups: null,
      group_id: 0,
      price: 0,
      userSelected: [],
      userId: '',
      copyProduct: null,
      copyTextarea: '',
      characteristicsOptions: null,
      productOptions: null,
      urlParams: {},
      productSelected: {
        id: '',
        name: ''
      },
      characteristicSelected: {
        id: '',
        name: ''
      },
      filterNamesParams: {}
    }),
    mounted () {
        this.fetchData()
        this.fetchGroup()
        this.fetchProducts()
        this.setUsers()
    },
    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },
    methods: {
      async fetchGroup () {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'groups/list').then(resp => {
          this.groups = this.listToTree(resp.data.data)
        })
      },
      async fetchProducts () {
        this.productOptions = null
        this.characteristicsOptions = null
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'product/names', this.filterNamesParams).then(resp => {
          this.productOptions = resp.data.products
          this.characteristicsOptions = resp.data.characteristics
        })
      },
      async fetchData () {
        this.urlParams['perPage'] = this.perPage
        const params = this.buildQuery() !== '' ? '&' + this.buildQuery() : ''
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.get(apiUrl + 'characteristics/products?page=' + this.currentPage + params).then(resp => {
          if (resp.data) {
            const data = resp.data
            this.items = data.data
            this.currentPage = data.current_page
            this.perPage = data.per_page
            this.totalItems = data.total
          }
        })
      },
      async removeItem (id) {
        this.$root.$refs.loading.start()
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        const result = confirm(this.$t('want_to_delete'))
        if (result) {
          await axios.delete(apiUrl + 'products/' + id)
          this.fetchData()
          this.$root.$refs.loading.finish()
        }
      },
      async removeItems () {
        this.$root.$refs.loading.start()
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        const result = confirm(this.$t('want_to_delete'))
        if (result) {
          await axios.post(apiUrl + 'products/delete-bulk')
          this.fetchData()
          this.$root.$refs.loading.finish()
        }
      },
      async setUsers () {
        // eslint-disable-next-line no-eval
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        const { data } = await axios.get(apiUrl + 'manager/list')
        this.users = data.data
      },
      async copyProductItem (id) {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'products/' + id + '/copy', {
          names: this.copyTextarea
        }).then(resp => {
          if (resp.data.status) {
            if (resp.data.status && resp.data.errors) {
              const errors = Object.entries(resp.data.errors).map(( [k, v] ) => ({ [k]: v }))
              let html = ''
              errors.forEach(item => {
                html += Object.values(item)
                html += '<br/>'
              })
              Swal.fire({
                icon: 'info',
                title: 'Інфо',
                html: html,
                reverseButtons: true,
                confirmButtonText: this.$t('ok'),
                cancelButtonText: this.$t('cancel')
              })
            } else {
              Swal.fire({
                icon: 'info',
                title: 'Скопійовано',
                text: 'Товари були скопійовані',
                reverseButtons: true,
                confirmButtonText: this.$t('ok'),
                cancelButtonText: this.$t('cancel')
              })
            }
            this.fetchData()
          }
        })
      },
      info (item, index, btn) {
        this.infoModal.title = 'Інформація про товар'
        let html = '<ul class="list-unstyled">'
            html += '<li><strong>Група:</strong> ' + item.group + '</li>'
            if (item.product) {
              html += '<li><strong>Товар:</strong> ' + item.product + '</li>'
            }
            if (item.characteristic) {
              html += '<li><strong>Характеристика:</strong> ' + item.characteristic + '</li>'
            }
            html += '<li><strong>Код товару:</strong> ' + item.sku + '</li>'
            html += '<li><strong>Довжина:</strong> ' + item.length + '</li>'
            html += '<li><strong>Ширина:</strong> ' + item.width + '</li>'
            html += '<li><strong>Висота:</strong> ' + item.height + '</li>'
            html += '<li><strong>Вага:</strong> ' + item.weight + '</li>'
            html += '<li><strong>Ємність:</strong> ' + item.volume + '</li>'
            html += '<li><strong>Опис:</strong> ' + item.description + '</li>'
            if (item.image) {
              html += '<li><strong>Фото:</strong> <img src="' + process.env.VUE_APP_ENDPOINT_URL + item.image + '" class="img-thumbnail" width="220"/></li>'
            }
            html += '<li><strong>Ціна:</strong> ' + item.price + '</li>'
            html += '<li><strong>Загальна кількість:</strong> ' + item.total_quantity + '</li>'
            html += '<li><strong>К-сть під виробництво:</strong> ' + item.production_quantity + '</li>'
            html += '<li><strong>Доданий:</strong> ' + item.created_at + '</li>'
            html += '</ul>'
        this.infoModal.content = html
        this.$root.$emit('bv::show::chResultmodal', this.infoModal.id, btn)
      },
      resetInfoModal () {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      copyModal (item, index, btn) {
        this.copyProduct = item
        this.$root.$emit('bv::show::modal', 'copyModal', btn)
      },
      selectAll (e) {
        const checked = e.target.checked
        if (checked) {
          this.selectIds = []
          if (!this.allSelected) {
            for (const item in this.items) {
              const elm = this.items[item]
              if (typeof elm !== 'undefined') {
                if (elm.type === 'product') {
                  this.selectIds.push(elm.id)
                } else if (elm.type === 'characteristic') {
                  this.selectedCharacteristicsIds.push(elm.id)
                }
                elm.selected = true
              }
            }
          }
        } else {
          this.selectIds = []
          this.selectedCharacteristicsIds = []
          this.items.forEach(el => {
            el.selected = false
          })
        }
      },
      selectItem (id, type, e) {
        const checked = e.target.checked
        if (type === 'product') {
          if (checked) {
            this.selectIds.push(id)
          } else {
            const idx = this.selectIds.findIndex(i => i === id)
            this.selectIds.splice(idx, 1)
          }
        } else if (type === 'characteristic') {
          if (checked) {
            this.selectedCharacteristicsIds.push(id)
          } else {
            const idx = this.selectedCharacteristicsIds.findIndex(i => i === id)
            this.selectedCharacteristicsIds.splice(idx, 1)
          }
        }
      },
      async setPriceToUsers () {
        if (this.price > 0) {
            if (this.selectIds.length > 0) {
              this.$root.$refs.loading.start()
              // send to backend changes
              const data = {
                  users: this.userSelected,
                  price: Number.parseFloat(this.price),
                  charas: this.selectedCharacteristicsIds,
                  products: this.selectIds
              }
              const apiUrl = process.env.VUE_APP_ENDPOINT_V1
              await axios.post(apiUrl + 'products/price', data).then(resp => {
                this.selectIds = []
                this.selectedCharacteristicsIds = []
                this.$root.$refs.loading.finish()
                // this.price = 0
                this.fetchData()
              })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: this.$t('characteristics_empty'),
                    text: this.$t('characteristics_empty_text'),
                    reverseButtons: true,
                    confirmButtonText: this.$t('ok'),
                    cancelButtonText: this.$t('cancel')
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: this.$t('price_empty'),
                text: this.$t('price_empty_text'),
                reverseButtons: true,
                confirmButtonText: this.$t('ok'),
                cancelButtonText: this.$t('cancel')
            })
        }
      },
      searching () {
        this.fetchData()
      },
      searchByUser (e) {
        this.userId = e.target.value
        this.fetchData()
      },
      clearFilter () {
        this.urlParams = {}
        this.fetchData()
      },
      searchByText (key, e) {
        this.urlParams[key] = e.target.value
        this.fetchData()
      },
      searchBySelect (key, e) {
        const value = e.target.value
        this.urlParams[key] = value
        this.fetchData()
      },
      onSelectProduct (val) {
        this.urlParams['product'] = val.id
        this.productSelected = val
        this.fetchData()
        this.filterNamesParams.product = val.id
        this.fetchProducts()
      },
      onSelectCharacteristic (val) {
        this.urlParams['characteristic'] = val.name === ' -- ' ? '' : val.name
        this.characteristicSelected = val
        this.fetchData()
        if (val.name !== ' -- ') {
          this.filterNamesParams.characteristic = val.name
        }
        this.fetchProducts()
      },
      searchByGroup (key, e) {
        const value = e.target.value
        // clear params
        this.urlParams = {}
        // set params
        this.urlParams[key] = value
        // clear selected
        this.productSelected = {}
        this.characteristicSelected = {}
        // clear params
        this.filterNamesParams = {}
        // set param
        this.filterNamesParams.group = value
        // refresh request
        this.fetchProducts()
        this.fetchData()
      },
      buildQuery () {
        return Object.entries(this.urlParams).map(pair => pair.map(encodeURIComponent).join('=')).join('&')
      },
      listToTree (list) {
        const map = {}
        let node
        const roots = []
        for (let i = 0; i < list.length; i += 1) {
          map[list[i].id] = i
          list[i].children = []
        }

        for (let i = 0; i < list.length; i += 1) {
          node = list[i]
          if (node.parent_id > 0) {
            // if you have dangling branches check that map[node.parentId] exists
            list[map[node.parent_id]].children.push(node)
          } else {
            roots.push(node)
          }
        }
        return roots
      }
    },
    watch: {
        currentPage: {
            handler: function (value) {
                this.fetchData().catch(error => {
                    console.log(error)
                })
            }
        },
        perPage: {
          handler: function (value) {
            this.fetchData()
          }
        }
    }
}
</script>
