<template>
  <b-tab :title="$t('groups')">
    <div class="row">
      <div class="col-7">
        <ul v-if="groups" class="list-unstyled">
          <li v-for="(group, index) in groups" :key="group.id">
            <div class="btn-group">
              <button class="btn btn-sm text-danger" @click="removeGroup(group.id)"><fa icon="times" /></button>
              <button class="btn btn-sm text-success" @click="showInput(index)">
                <fa v-if="showInputStatus === index" icon="minus" class="text-danger" />
                <fa v-else icon="plus"/>
              </button>
            </div>
            <input v-if="showInputEditChild === group.id" type="text" class="form-control-float form-control-sm" :value="group.name" @keyup.enter="editGroup(group.id, $event)">
            <strong v-else @click="showEditInput(group.id)">{{ group.name }}</strong>
            &nbsp;
            <input type="checkbox" :value="group.id" :checked="group.status" @change="changeStatus">
            <div v-if="showInputStatus === index" class="input-group ml-5">
              <input type="text" class="form-control form-control-sm" :placeholder="$t('subgroup_name')" @keyup.enter="addGroup(group.id, $event)">
            </div>  
            <!-- child -->
            <ul v-if="group.children.length > 0" class="list-unstyled ml-4 text-muted">
              <li v-for="child in group.children" :key="child.id">
                  <div class="btn-group">
                    <button class="btn btn-sm text-danger" @click="removeGroup(child.id)"><fa icon="times" /></button>
                    <button class="btn btn-sm text-success" @click="showInputChild(child.id)">
                      <fa v-if="showInputStatusChild === child.id" icon="minus" class="text-danger" />
                      <fa v-else icon="plus"/>
                    </button>
                  </div>
                  <!-- input edit -->
                <input v-if="showInputEditChild === child.id" type="text" class="form-control-float form-control-sm" :value="child.name" @keyup.enter="editGroup(child.id, $event)">
                <span v-else @click="showEditInput(child.id)">{{ child.name }}</span>
                &nbsp;
                <input type="checkbox" :value="child.id" :checked="child.status" @change="changeStatus">
                <div v-if="showInputStatusChild === child.id" class="input-group ml-5">
                  <input type="text" class="form-control form-control-sm" :placeholder="$t('subgroup_name')" @keyup.enter="addGroup(child.id, $event)">
                </div> 
                <!-- sub child -->
                <ul v-if="child.children" class="list-unstyled ml-5">
                  <li v-for="sub in child.children" :key="sub.id" class="text-info">
                    <div class="btn-group">
                      <button class="btn btn-sm text-danger" type="button" @click="removeGroup(sub.id)"><fa icon="times" /></button>
                    </div>
                    <input v-if="showInputEditChild === sub.id" type="text" class="form-control-float form-control-sm" :value="sub.name" @keyup.enter="editGroup(sub.id, $event)">
                    <span v-else @click="showEditInput(sub.id)">{{ sub.name }}</span>
                    &nbsp;
                    <input type="checkbox" :value="sub.id" :checked="sub.status" @change="changeStatus">
                  </li>
                </ul>
              </li>
              <!--<li v-if="showInputStatus === index">
                <div class="input-group ml-5">
                  <input type="text" class="form-control form-control-sm" :placeholder="$t('subgroup_name')" @keyup.enter="addGroup(group.id, $event)">
                </div>  
              </li>-->
            </ul>
          </li>
          <li class="mt-2">
            <div class="input-group">
              <input type="text" class="form-control form-control-sm" :placeholder="$t('group_name')" @keyup.enter="addGroup('', $event)">
            </div>  
          </li>
        </ul>
      </div>
    </div>
  </b-tab>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2'
export default {
  data: () => ({
    groups: null,
    showInputStatus: null,
    showInputStatusChild: null,
    showInputEditChild: null,
    groupName: ''
  }),
  mounted () {
    this.getGroups()
  },
  methods: {
    async getGroups () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.get(apiUrl + 'groups/tree').then(resp => {
        this.groups = resp.data.groups
      })
    },
    async addGroup (groupId, e) {
      const group = groupId
      const value = e.target.value
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'groups', {
        group_id: group,
        text: value
      }).then(resp => {
        if (resp.data.status) {
          this.groupName = ''
          this.showInputStatus = null
          e.target.value = ''
          this.getGroups()
        }
      })
    },
    async editGroup (id, e) {
      const value = e.target.value
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.put(apiUrl + 'groups/' + id, { value: value }).then(resp => {
        if (resp.data.status) {
          this.showInputEditChild = null
          this.getGroups()
        }
      })
    },
    async removeGroup (id) {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.delete(apiUrl + 'groups/' + id).then(resp => {
        if (resp.data.status) {
          this.getGroups()
        } else {
          Swal.fire({
            icon: 'error',
            title: this.$t('error'),
            text: resp.data.msg,
            reverseButtons: true,
            confirmButtonText: this.$t('ok'),
            cancelButtonText: this.$t('cancel')
          })
        }
      })
    },
    async changeStatus (e) {
      const checked = e.target.checked
      const value = e.target.value
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'groups/status', {
        checked: checked,
        id: value
      }).then(resp => {
        if (resp.data.status) {
          this.getGroups()
        }
      })
    },
    async sortGroup (id, sort, index, parentId) {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'groups/sort', {
        id: id,
        sort: sort,
        index: index,
        parent_id: parentId
      }).then(resp => {
        if (resp.data.status) {
          this.getGroups()
        }
      })
    },
    showEditInput(id) {
      if (this.showInputEditChild !== null) {
        this.showInputEditChild = null
      } else {
        this.showInputEditChild = id
      }
    },
    showInput (index) {
      if (this.showInputStatus !== null) {
        this.showInputStatus = null
      } else {
        this.showInputStatus = index
      }
    },
    showInputChild (index) {
      if (this.showInputStatusChild !== null) {
        this.showInputStatusChild = null
      } else {
        this.showInputStatusChild = index
      }
    }
  }
}
</script>
<style scoped>
.form-control-float {
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
    -webkit-box-shadow: 0 1px 2px rgb(0 0 0 / 5%);
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%);
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
</style>
