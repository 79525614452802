<template>
<b-tab :title="$t('stages')">
    <b-row class="mb-2">
        <b-col md="12">
          <div class="text-right">
            <b-button-group>
              <b-button size="sm" variant="danger" @click="removeItems($event)" :title="$t('delete_all')">
                <fa icon="trash" />
              </b-button>
            </b-button-group>
          </div>
        </b-col>
      </b-row>
      <b-table v-if="items" striped show-empty small :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage">
        <template slot="top-row" slot-scope="{ fields }">
          <td v-for="field in fields" :key="field.key">
            <b-form-input v-if="field.typesort === 'text'" size="sm" :placeholder="field.label" @keyup.enter="searchByText(field.key, $event)"></b-form-input>
            <div v-if="field.key === 'group'" class="form-group" >
              <select class="form-control form-control-sm" @change="searchByGroup(field.key, $event)">
                <option value=""> -- </option>
                <optgroup v-for="group in groups" :key="group.id" :label="group.name">
                  <option v-for="child in group.children" :key="child.id" :value="child.id">{{ child.name }}</option>
                </optgroup>
              </select>
            </div>
            <b-input-group v-if="field.key === 'product'">
              <list-select
              v-if="productOptions"
                :list="productOptions"
                option-value="id"
                option-text="name"
                :placeholder="$t('nothing_selected')"
                :selected-item="productSelected"
                @select="onSelectProduct"
                class="form-control"></list-select>
            </b-input-group>
            <div v-if="field.key === 'characteristic'" class="form-group">
              <list-select
              v-if="characteristicsOptions"
                :list="characteristicsOptions"
                option-value="id"
                option-text="name"
                :placeholder="$t('nothing_selected')"
                :selected-item="characteristicSelected"
                @select="onSelectCharacteristic"
                class="form-control"></list-select>
            </div>
            <div v-if="field.key === 'stage'" class="form-group">
              <list-select
              v-if="stagesOptions"
                :list="stagesOptions"
                option-value="id"
                option-text="name"
                :placeholder="$t('nothing_selected')"
                :selected-item="stageSelected"
                @select="onSelectStage"
                class="form-control"></list-select>
            </div>
            <div v-if="field.key === 'visibility'" class="form-group">
              <select class="form-control form-control-sm" @change="searchBySelect(field.key, $event)">
                <option value="">--</option>
                <option value="true">{{ $t('enabled') }}</option>
                <option value="false">{{ $t('disabled') }}</option>
              </select>
            </div>
            <div  v-if="field.key === 'status'" class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="stages-select-statuses" @change="selectStatuses">
              <label class="custom-control-label" for="stages-select-statuses"></label>
            </div>
          </td>
        </template>
        <template v-slot:head(selected)>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="stages-select-all" @click="selectAll" v-model="allSelected">
            <label class="custom-control-label" for="stages-select-all"></label>
          </div>
        </template>
        <template v-slot:cell(selected)="row">
          <div class="custom-control custom-checkbox">
            <input
            type="checkbox"
            class="custom-control-input"
            :id="`checkbox-${row.item.id}`"
            :name="`checkbox-${row.item.id}`"
            :value="row.item.id"
            :checked="row.item.selected"
            @change="selectItem(row.item.id, row.item.type, $event)">
            <label class="custom-control-label" :for="`checkbox-${row.item.id}`"></label>
          </div>
        </template>
        <template v-slot:cell(visibility)="row">
          <span v-if="row.item.visibility !== null">
            <span v-if="row.item.visibility" class="badge badge-success">{{ $t('enabled') }}</span>
            <span v-else class="badge badge-danger">{{ $t('disabled') }}</span>
          </span>
        </template>
        <template v-slot:cell(status)="row">
          <div v-if="row.item.status !== null" class="custom-control custom-checkbox">
            <input
            type="checkbox"
            class="custom-control-input"
            :id="`visible-${row.item.originalId}`"
            :value="row.item.id"
            v-model="row.item.status"
            @change="setStageVisible(row.item.id, $event)">
            <label class="custom-control-label" :for="`visible-${row.item.originalId}`"></label>
          </div>
        </template>
        <template v-slot:cell(actions)="row">
          <b-button-group>
            <router-link v-if="user.role === 'admin'" :to="{ name: 'products.edit', params: { 'id': row.item.productId } }" class="btn btn-sm btn-warning"><fa icon="pencil-alt" /></router-link>
            <b-button size="sm" variant="danger" @click="removeItem(row.item.id)">
              <fa icon="trash" />
            </b-button>
          </b-button-group>
        </template>
      </b-table>
    <!-- Pagination -->
    <b-row>
      <b-col>
        <b-pagination size="sm" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"/>
      </b-col>
      <b-col>
        <b-form-group
          label="К-сть на стр"
          label-for="per-stage-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-stage-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
</b-tab>
</template>
<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import { ListSelect } from 'vue-search-select'
export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('products') }
    },
    components: {
      ListSelect
    },
    data: () => ({
      users: [],
      items: [],
      fields: [
        {
          key: 'selected',
          label: '',
          sortable: false
        },
        {
          key: 'sku',
          label: 'Код товару',
          typesort: 'text'
        },
        {
          key: 'group',
          label: 'Група',
          typesort: 'select'
        },
        {
          key: 'product',
          label: 'Назва',
          typesort: 'select'
        },
        {
          key: 'characteristic',
          label: 'Характеристика',
          class: 'text-center',
          typesort: 'select'
        },
        {
          key: 'stage',
          label: 'Етап',
          class: 'text-center',
          typesort: 'select'
        },
        {
          key: 'visibility',
          label: 'Видимість в зам',
          class: 'text-center',
          typesort: 'select'
        },
        {
          key: 'status',
          label: '',
          class: 'text-center',
          typesort: 'checkbox'
        },
        {
          key: 'actions',
          label: 'Дії',
          class: 'text-center'
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      pageOptions: [5, 10, 15, 50, 100, 250, 500, 1000, 1500, 2000, 5000],
      infoModal: {
        id: 'stage-modal',
        title: '',
        content: ''
      },
      selected: [],
      allSelected: false,
      selectIds: [],
      selectedCharacteristicsIds: [],
      search: '',
      search_sku: '',
      groups: null,
      group_id: 0,
      price: 0,
      userSelected: [],
      characteristicsOptions: null,
      productOptions: null,
      stagesOptions: null,
      urlParams: {},
      productSelected: {
        id: '',
        name: ''
      },
      characteristicSelected: {
        id: '',
        name: ''
      },
      stageSelected: {
        id: '',
        name: ''
      },
      filterNamesParams: {}
    }),
    mounted () {
        this.fetchData()
        this.fetchGroup()
        this.fetchProducts()
    },
    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },
    methods: {
      async fetchGroup () {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'groups/list').then(resp => {
          this.groups = this.listToTree(resp.data.data)
        })
      },
      async fetchProducts () {
        this.productOptions = null
        this.characteristicsOptions = null
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'product/names', this.filterNamesParams).then(resp => {
          this.productOptions = resp.data.products
          this.characteristicsOptions = resp.data.characteristics
          this.stagesOptions = resp.data.stages
        })
      },
      async fetchData () {
        this.urlParams['perPage'] = this.perPage
        const params = this.buildQuery() !== '' ? '&' + this.buildQuery() : ''
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.get(apiUrl + 'stages/products?page=' + this.currentPage + params).then(resp => {
          if (resp.data) {
            const data = resp.data
            this.items = data.data
            this.currentPage =  data.current_page
            this.perPage = data.per_page
            this.totalItems = data.total
          }
        })
      },
      async removeItem (id) {
        this.$root.$refs.loading.start()
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        const result = confirm(this.$t('want_to_delete'))
        if (result) {
          await axios.delete(apiUrl + 'products/' + id)
          this.fetchData()
          this.$root.$refs.loading.finish()
        }
      },
      async removeItems () {
        this.$root.$refs.loading.start()
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        const result = confirm(this.$t('want_to_delete'))
        if (result) {
          await axios.post(apiUrl + 'products/delete-bulk')
          this.fetchData()
          this.$root.$refs.loading.finish()
        }
      },
      async setUsers (e) {
        // eslint-disable-next-line no-eval
        const btn = eval(e.target.ariaExpanded)
        if (!btn && this.users.length === 0) {
          const apiUrl = process.env.VUE_APP_ENDPOINT_V1
          const { data } = await axios.get(apiUrl + 'manager/list')
          this.users = data.data
        }
      },
      searching () {
        this.fetchData()
      },
      changeGroup () {
        this.fetchData()
      },
      selectAll (e) {
        const checked = e.target.checked
        if (checked) {
          this.selectIds = []
          if (!this.allSelected) {
            for (const item in this.items) {
              const elm = this.items[item]
              if (typeof elm !== 'undefined') {
                if (elm.type === 'product') {
                  this.selectIds.push(elm.id)
                } else if (elm.type === 'characteristic') {
                  this.selectedCharacteristicsIds.push(elm.id)
                }
                elm.selected = true
              }
            }
          }
        } else {
          this.selectIds = []
          this.selectedCharacteristicsIds = []
          this.items.forEach(el => {
            el.selected = false
          })
        }
      },
      selectItem (id, type, e) {
        const checked = e.target.checked
        if (type === 'product') {
          if (checked) {
            this.selectIds.push(id)
          } else {
            const idx = this.selectIds.findIndex(i => i === id)
            this.selectIds.splice(idx, 1)
          }
        } else if (type === 'stage') {
          if (checked) {
            this.selectedCharacteristicsIds.push(id)
          } else {
            const idx = this.selectedCharacteristicsIds.findIndex(i => i === id)
            this.selectedCharacteristicsIds.splice(idx, 1)
          }
        }
        const item = this.items.find(i => i.id === id)
        if (typeof item !== 'undefined') {
          item.selected = checked
        }
      },
      async setStageVisible (stageId, e) {
        if (stageId) {
          this.$root.$refs.loading.start()
          const apiUrl = process.env.VUE_APP_ENDPOINT_V1
          const checked = e.target.checked
          await axios.post(apiUrl + 'products/stage', { status: checked, stage_id: stageId }).then(resp => {
            if (resp.data.status) {
              this.$root.$refs.loading.finish()
              this.fetchData()
            }
          })
        }
      },
      async selectStatuses (e) {
        const checked = e.target.checked
        const selected = this.items.filter(i => i.selected)
        console.log(selected)
        if (selected.length > 0) {
          const ids = selected.map(i => i.originalId)
          const apiUrl = process.env.VUE_APP_ENDPOINT_V1
          await axios.post(apiUrl + 'products/stage/bulk', { status: checked, ids: ids }).then(resp => {
            if (resp.data.status) {
              this.$root.$refs.loading.finish()
              this.fetchData()
            }
          })
        }
      },
      searchByText (key, e) {
        this.urlParams[key] = e.target.value
        this.fetchData()
      },
      searchBySelect (key, e) {
        const value = e.target.value
        this.urlParams[key] = value
        this.fetchData()
        if (key === 'visibility') {
          this.filterNamesParams.visibility = value
          this.fetchProducts()
        }
      },
      onSelectProduct (val) {
        this.urlParams['product'] = val.id
        this.productSelected = val
        this.fetchData()
        this.filterNamesParams.product = val.id
        this.fetchProducts()
      },
      onSelectCharacteristic (val) {
        this.urlParams['characteristic'] = val.name === ' -- ' ? '' : val.name
        this.characteristicSelected = val
        this.fetchData()
        if (val.name !== ' -- ') {
          this.filterNamesParams.characteristic = val.name
        }
        this.fetchProducts()
      },
      onSelectStage (val) {
        this.urlParams['stage'] = val.name === ' -- ' ? '' : val.name
        this.stageSelected = val
        this.fetchData()
        if (val.name !== ' -- ') {
          this.filterNamesParams.stage = val.name
        }
        this.fetchProducts()
      },
      searchByGroup (key, e) {
        const value = e.target.value
        // clear params
        this.urlParams = {}
        // set params
        this.urlParams[key] = value
        // clear selected
        this.productSelected = {}
        this.characteristicSelected = {}
        this.stageSelected = {}
        // clear params
        this.filterNamesParams = {}
        // set param
        this.filterNamesParams.group = value
        // refresh request
        this.fetchProducts()
        this.fetchData()
      },
      buildQuery () {
        return Object.entries(this.urlParams).map(pair => pair.map(encodeURIComponent).join('=')).join('&')
      },
      listToTree (list) {
        const map = {}
        let node
        const roots = []
        for (let i = 0; i < list.length; i += 1) {
          map[list[i].id] = i
          list[i].children = []
        }

        for (let i = 0; i < list.length; i += 1) {
          node = list[i]
          if (node.parent_id > 0) {
            // if you have dangling branches check that map[node.parentId] exists
            list[map[node.parent_id]].children.push(node)
          } else {
            roots.push(node)
          }
        }
        return roots
      }
    },
    watch: {
        currentPage: {
            handler: function (value) {
                this.fetchData().catch(error => {
                    console.log(error)
                })
            }
        },
        perPage: {
          handler: function (value) {
            this.fetchData()
          }
        }
    }
}
</script>
